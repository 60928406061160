import { Translations } from "../types";

type DotNotation<T, Prefix extends string = ''> = {
  [K in keyof T]: T[K] extends object
  ? DotNotation<T[K], `${Prefix}${K & string}.`>
  : `${Prefix}${K & string}`
}[keyof T];

export type DefaultTranslations = {
  [K in DotNotation<Translations>]: string;
};

export const enDefault: DefaultTranslations = {
  "invoicesPage.card.back-button": "Invoices",
  "invoicesPage.Customer liable for the tax under the reverse-charge mechanism": "Customer liable for the tax under the reverse-charge mechanism",
  "accountPage.email": "Email",
  "accountPage.changeEmail": "Change email",
  "accountPage.password": "Password",
  "accountPage.changePassword": "Change password",
  "accountPage.billingInfo": "Billing Info",
  "accountPage.updateBillingInfo": "Update billing info",
  "accountPage.billingInfo.company": "Company",
  "accountPage.billingInfo.email": "Email",
  "accountPage.billingInfo.phoneNumber": "Phone Number",
  "accountPage.billingInfo.house": "House",
  "accountPage.billingInfo.apartment": "Apartment",
  "accountPage.billingInfo.city": "City",
  "accountPage.billingInfo.zipCode": "Zip Code",
  "accountPage.billingInfo.country": "Country",
  "authentication.pageTitle": "Customer portal",
  "authentication.enterEmail": "Enter your email address to login",
  "authentication.terms": "Terms of use",
  "authentication.privacy": "Privacy policy",
  "authentication.label.email": "Email",
  "authentication.label.password": "Password",
  "authentication.label.confirm": "Confirm password",
  "authentication.confirm.description": "Password must consist of 8+ characters containing upper and lower case letters, numbers and symbols.",
  "authentication.agreement": "I've read and agree to",
  "authentication.and": "and",
  "authentication.login": "Log in",
  "authentication.haveAccount": "Already have an account?",
  "authentication.enterOTP": "Enter the one time code sent to your email",
  "authentication.resendOTP": "Resend the one time code",
  "authentication.signIn": "Sign in",
  "authentication.signUp": "Sign up",
  "authentication.continue": "Continue",
  "authentication.enterPassword": "Enter your password",
  "authentication.signWithOTP": "Sign in with one time code",
  "authentication.forgotPassword": "Forgot your password?",
  "authentication.retry": "Retry in {{count}} seconds",
  "authentication.successRedirect": "Status confirmed, redirect...",
  "authentication.errorRedirect": "Status not confirmed, redirect...",
  "authentication.awaitingRedirect": "Checking session status, please wait",
  "invoicesPage.label.invoice": "Invoice",
  "invoicesPage.label.status": "Status",
  "invoicesPage.label.createdOn": "Created on",
  "invoicesPage.label.amountDue": "Amount due",
  "invoicesPage.label.actions": "Actions",
  "invoicesPage.payInvoice": "Pay invoice",
  "invoicesPage.openInvoice": "Open invoice",
  "invoicesPage.downloadInvoice": "Download PDF",
  "invoicesPage.status.paid": "Paid",
  "invoicesPage.status.paymentDue": "Payment due",
  "invoicesPage.emptyText": "You dont have any invoices yet",
  "invoicesPage.dueDate": "Date due",
  "invoicesPage.from": "From",
  "invoicesPage.to": "To",
  "invoicesPage.due": "due",
  "invoicesPage.table.items": "Items",
  "invoicesPage.table.unit": "Unit",
  "invoicesPage.table.unitPrice": "Unit Price",
  "invoicesPage.table.taxRate": "Tax Rate",
  "invoicesPage.table.discount": "Discount",
  "invoicesPage.table.amount": "Amount",
  "invoicesPage.table.amountDue": "AMOUNT DUE:",
  "invoicesPage.table.adjustments": "ADJUSTMENTS:",
  "invoicesPage.table.credits": "CREDITS:",
  "invoicesPage.table.totalDiscount": "TOTAL DISCOUNTS:",
  "invoicesPage.table.subtotal": "SUBTOTAL:",
  "invoicesPage.table.totalBeforeTax": "TOTAL BEFORE TAX:",
  "invoicesPage.table.vat": "VAT",
  "invoicesPage.table.amountPaid": "AMOUNT PAID:",
  "invoicesPage.table.total": "TOTAL:",
  "navigation.subscriptions": "Subscriptions",
  "navigation.invoices": "Invoices",
  "navigation.plans": "Plans",
  "navigation.addons": "Addons",
  "navigation.account": "Account",
  "navigation.signOut": "Sign Out",
  "pagination.rowsPerPage": "Rows per page:",
  "pagination.countOfCount": "of",
  "pagination.nextPage": "Go to next page",
  "pagination.prevPage": "Go to previos page",
  "subscriptionsPage.Forever": "Forever",
  "subscriptionsPage.SpecificItemPrice": "item amount",
  "subscriptionsPage.OneTime": "One Time",
  "subscriptionsPage.InvoiceAmount": "total amount",
  "subscriptionsPage.startsOn": "Start date",
  "subscriptionsPage.expiresOn": "Expire date",
  "subscriptionsPage.addons": "Addons:",
  "subscriptionsPage.paySubscription": "Pay subscription",
  "subscriptionsPage.billingPeriod_from": "From",
  "subscriptionsPage.billingPeriod_to": "To",
  "subscriptionsPage.billingPeriod": "Billing period:",
  "validation.authentication.isEmail": "Incorrect email format",
  "validation.authentication.requiredField": "This field cannot be left blank",
  "validation.authentication.password.rule.min": "Password must be at least 8 characters long",
  "validation.authentication.password.rule.match": "Passwords must match",
  "validation.authentication.privacy.accepted": "Privacy & Terms must be accepted",
  "validation.authentication.password.rule.characters": "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character",
  "frequencies.Daily": "Daily",
  "frequencies.Weekly": "Weekly",
  "frequencies.Monthly": "Monthly",
  "frequencies.Yearly": "Yearly",
  "frequencies.Days": "Days",
  "frequencies.Weeks": "Weeks",
  "frequencies.Months": "Months",
  "frequencies.Years": "Years",
  "pageTitles.subscriptionsPageTitle": "Subscriptions",
  "pageTitles.invoicesPageTitle": "Invoices",
  "pageTitles.plansPageTitle": "Plans",
  "pageTitles.invoiceCardPageTitle": "Invoice",
  "pageTitles.addonsPageTitle": "Addons",
  "pageTitles.accountPageTitle": "Account",
  "pageTitles.authenticationPageTitle": "Customer portal",
  "pageTitles.subscriptionHubPageTitle": "Subscription setup",
  "subscriptionsPage.emptySubscriptions": "You don't have any subscriptions yet",
  "subscriptionsPage.discountOn": "discount on",
  "plans.perUnit": "Per unit",
  "plans.letsGoButton": "Let's go",
  "plans.quantity": "Quantity",
  "accountPage.billingInfo.vatNumber": "Tax number",
  "accountPage.billingForm.title": "Billing info",
  "accountPage.billingForm.updateButton": "Update billing info",
  "accountPage.billingForm.label.companyName": "Company name",
  "accountPage.billingForm.label.street": "Street",
  "accountPage.billingForm.label.house": "House",
  "accountPage.billingForm.label.apartment": "Apartment",
  "accountPage.billingForm.label.city": "City",
  "accountPage.billingForm.label.zipCode": "Zip code",
  "accountPage.billingForm.label.countryCode": "Country",
  "accountPage.billingForm.label.stateCode": "Country state",
  "accountPage.billingForm.label.email": "Email",
  "accountPage.billingForm.label.phone": "Phone Number",
  "accountPage.billingForm.label.vatNumber": "Tax number",
  "authentication.otpDescription": "Sent code is valid for single use, expiring in 2 minutes.",
  "invoicesPage.discount.fixed": " (Fixed)",
  "invoicesPage.discount.percentage": "(Percentage)",
  "plans.subscribeButton": "Subscribe",
  "subscriptionsPage.createSubscription": "Create subscription",
  "subscriptionsPage.completeTrial": "Finish trial",
  "subscriptionHub.add_payment_method": "Add payment method",
  "subscriptionHub.freeQuantity": " (Free quantity {{quantity}})",
  "subscriptionHub.next": "Next",
  "subscriptionHub.step.add_payment_method": "Add payment method",
  "subscriptionHub.step.select_plan": "Subscription details",
  "subscriptionHub.step.set_billing": "Billing Setup",
  "subscriptionHub.step.summary": "Order Summary",
  "subscriptionHub.back": "Go to the plan selection",
  "subscriptionHub.summary.addons": "Addons",
  "subscriptionHub.summary.billingInfo": "Billing address",
  "subscriptionHub.summary.plan": "Plan",
  "subscriptionHub.summary.total": "Total",
  "subscriptionHub.save_billing": "Save billing info",
  "subscriptionHub.payment_method_expires_on": "Expires on",
  "subscriptionHub.payment_method_not_found": "Payment method not found",
  "subscriptionHub.payment_method_via": "via",
  "subscriptionHub.summary.pay_now": "Pay now",
  "subscriptionHub.summary.payment_method": "Payment method",
  "subscriptionHub.summary.start_trial": "Start free trial",
  "accountPage.field_optional": "(Optional)",
  "addonsPage.addAddonButton": "Add",
  "addonsPage.selectSubscriptionModal_emptyText": "This addon is not compatible with your existing subscriptions. To add this Addon, select the appropriate Plan for your subscription. ",
  "addonsPage.selectSubscriptionModal_title": "Select subscription",
  "subscriptionHub.lineItems.actions": "Actions",
  "subscriptionHub.lineItems.actions.changePlan": "Change plan",
  "subscriptionHub.lineItems.actions.delete": "Delete",
  "subscriptionHub.lineItems.actions.mandatory": "Mandatory",
  "subscriptionHub.lineItems.amount": "Amount",
  "subscriptionHub.lineItems.continueButton": "Continue",
  "subscriptionHub.lineItems.items": "Items",
  "subscriptionHub.lineItems.quantity": "Quantity",
  "subscriptionHub.lineItems.total": "Total",
  "subscriptionHub.us.cancelConfirmModal.no": "Discard",
  "subscriptionHub.us.cancelConfirmModal.title": "Are you sure you want to cancel this subscription?",
  "subscriptionHub.us.cancelConfirmModal.yes": "Confirm",
  "subscriptionHub.us.cancelSubscription": "Cancel subscription",
  "subscriptionHub.us.scheduledChanges.amount": "Amount",
  "subscriptionHub.us.scheduledChanges.cancelChanges": "Cancel changes",
  "subscriptionHub.us.scheduledChanges.price": "Price",
  "subscriptionHub.us.scheduledChanges.quantity": "Quantity",
  "subscriptionHub.us.scheduledChanges.title": "Scheduled changes",
  "subscriptionHub.us.step_changePlan": "Select Plan",
  "subscriptionHub.us.step_lineItems": "Subscription setup",
  "subscriptionHub.us.availableAddons": "Available addons",
  "subscriptionHub.us.scheduledChanges.cancelConfrim.no": "Discard",
  "subscriptionHub.us.scheduledChanges.cancelConfrim.yes": "Confirm",
  "subscriptionHub.us.scheduledChanges.cancelConfrimLabel": "Are you sure you want to cancel changes?",
  "subscriptionHub.us.scheduledChanges.changes_aplies": "Changes will be applied on",
  "accountPage.change_email.confirmEmail": "Confirm email",
  "accountPage.change_email.emailForm": "Enter your new email address",
  "accountPage.change_email.modalTitle": "Change email",
  "accountPage.change_email.otp_title": "Enter the one time code sent to your current email",
  "accountPage.change_email.otpExpiringDescription": "Sent code is valid for single use, expiring in 2 minutes.",
  "accountPage.change_email.submitChange": "Submit",
  "accountPage.change_password.form_confirmPassword": "Confirm password",
  "accountPage.change_password.modalTitle-ChangePassword": "Change password",
  "accountPage.change_password.modalTitle-SetPassword": "Set password",
  "accountPage.change_password.newPassword": "New Password",
  "accountPage.change_password.otp_description": "Sent code is valid for single use, expiring in 2 minutes.",
  "accountPage.change_password.otp_title": "Enter the one time code sent to your email",
  "accountPage.change_password.form_password": "Password",
  "accountPage.change_password.submit": "Submit",
  "accountPage.change_password.confirm": "Confirm",
  "authentication.otpSentMessage": "One time code sent!",
  "accountPage.change_password.success": "Password successfully set!",
  "common.somethingwentwrong": "Something went wrong.",
  "pageTitles.chargesPageTitle": "Charges",
  "navigation.charges": "Charges",
  "accountPage.billingForm.update.success": "Billing address successfully updated",
  "accountPage.billingForm.save": "Update",
  "pageTitles.singlePaymentPageTitle": "Single payment",
  "invoicesPage.label.total": "Total",
  "charges.singlePaymentTable_title": "Charges history",
  "charges.singlePaymentTable_items": "Items",
  "charges.singlePaymentTable_status": "Status",
  "charges.singlePaymentTable_status_paid": "Paid",
  "charges.singlePaymentTable_status_cancelled": "Cancelled",
  "charges.singlePaymentTable_status_pending_payment": "Pending Payment",
  "charges.singlePaymentTable_created_at": "Created at",
  "singlePayment.available_charges": "Available charges",
  "singlePayment.step_billing_setup": "Billing Setup",
  "singlePayment.step_select_charges": "Items Setup",
  "charges.Buy": "Buy",
  "charges.Add": "Add",
  "singlePayment.no_selected_items": "No selected items",
  "singlePayment.charges": "Charges",
  "singlePayment.pay_now": "Pay now",
  "singlePayment.total": "Total",
  "charges.singlePaymentTable_invoice": "Invoice",
  "subscriptionHub.Discount": "Discount",
  "subscriptionHub.Addon": "Addon",
  "subscriptionHub.Charge": "Charge",
  "subscriptionHub.Plan": "Plan",
  "accountPage.payment_methods": "Payment methods",
  "accountPage.payment_methods_add_payment_button": "Add payment method",
  "accountPage.payment_methods_add_payment-modal_title": "Select the currency",
  "accountPage.payment_methods_not_found": "You do not have any payment methods added yet",
  "accountPage.payment_method_option_delete": "Delete",
  "accountPage.payment_method_option_set-primary": "Make default",
  "accountPage.payment_method_delete-modal_title": "Are you sure you want to delete the payment method?",
  "accountPage.payment_method_delete-modal_yes": "Confirm",
  "accountPage.payment_method_delete-modal_no": "Cancel",
  "subscriptionHub.payment_method_currencies": "Billing currencies:",
  "subscriptionHub.payment_method_created_at": "Created at"
}