import { PropsWithChildren, createContext, useState } from "react";

import { AppUriPath } from "app/AppRoutes";
import { BackdropLoader } from "app/views/components/common/BackdropLoader";
import { getApies } from "app/api/functions/getApies";
import { localStorageUtil } from "app/helpers/utils/localStorageUtil";
import { isAbsoluteUrl } from "app/helpers/functions/isAbsoluteUrl";

type OwnProps = PropsWithChildren;

type SignInRequestParams = {
    email: string;
    password?: string;
    code?: string;
}

type SignUpRequestParams = {
    email: string;
    password: string;
}

export interface IAuthContextProvider {
    signIn: (args: SignInRequestParams) => Promise<boolean>;
    signOut: () => void;
    setIsAuthenticated: (arg: boolean) => void;
    signUp: (args: SignUpRequestParams) => Promise<string>;
    isAuthenticated: boolean;
}

export const AuthContext = createContext<IAuthContextProvider>(undefined as any);

export const AuthProvider: React.FC<OwnProps> = ({ children }: OwnProps) => {
    const { getItem, setItem, removeItem, removeItems } = localStorageUtil();
    const { useSignInWithOTPMutation, useSignInWithPasswordMutation, useSignUpMutation, useSignOutMutation } = getApies().authenticationEndPoints;
    const { useGetPortalConfigsQuery, useGetUserInfoQuery } = getApies().portalConfigsEndPoints;

    const notFoundPage = window.location.pathname === AppUriPath.notfound;
    const { isSuccess, isFetching } = useGetPortalConfigsQuery(undefined, { skip: notFoundPage });
    useGetUserInfoQuery(undefined, { skip: notFoundPage || !getItem("isAuthenticated") });

    const [signUp] = useSignUpMutation();
    const [signOut] = useSignOutMutation();
    const [signInPassword] = useSignInWithPasswordMutation();
    const [signInOTP] = useSignInWithOTPMutation();

    const onSignUp = async (args: SignUpRequestParams) => {
        return await signUp({ email: args.email, password: args.password }).unwrap().then(res => res);
    }

    const onSignIn = async (args: SignInRequestParams) => {
        try {
            if (args.code) {
                await signInOTP({ code: args.code, email: args.email })
                    .unwrap().then(setSignedIn)
            }

            if (args.password) {
                await signInPassword({ password: args.password, email: args.email })
                    .unwrap().then(setSignedIn)
            }
            return true
        }
        catch {
            removeItem("isAuthenticated")
            setValue(prev => {
                return {
                    ...prev,
                    isAuthenticated: false
                }
            })
            return false
        }

    }

    const setSignedIn = () => {
        const hasCheckout = !!getItem("checkout");
        setItem("isAuthenticated", JSON.stringify("true"))
        setValue(prev => {
            return {
                ...prev,
                isAuthenticated: true
            }
        })
        hasCheckout && window.location.replace(AppUriPath.onboarding)
    }

    const onSignOut = async () => {
        await signOut().unwrap().then(({ redirectUrl }) => {
            removeItems(["localStorageForm", "userInfo", "isAuthenticated"]);
            setValue(prev => {
                return {
                    ...prev,
                    isAuthenticated: false
                }
            })
            if (!!redirectUrl) {
                const baseUrl = `${window.location.protocol}//${window.location.host}`;
                isAbsoluteUrl(redirectUrl)
                    ? window.location.replace(redirectUrl)
                    : window.location.replace(baseUrl + redirectUrl)
            } else window.location.reload();
        })
            .catch(() => { });
    }
    const setIsAuthenticated = async (arg: boolean) => {
        setValue(prev => {
            return {
                ...prev,
                isAuthenticated: arg
            }
        })
    }

    const [value, setValue] = useState({
        isAuthenticated: !!getItem("isAuthenticated"),
        setIsAuthenticated: setIsAuthenticated,
        signIn: onSignIn,
        signOut: onSignOut,
        signUp: onSignUp
    });

    const showChildren = isSuccess || notFoundPage;
    return <AuthContext.Provider value={value}>
        {!!showChildren && children}
        <BackdropLoader open={isFetching} />
    </AuthContext.Provider>
}
