import {
    BillingInfoModel,
    ChangeEmailRequestParams,
    ChangePasswordRequestParams,
    CheckCustomerRequestParams,
    CheckCustomerResponseDto,
    GetSupportCountriesResponseDataModel,
    SetPasswordRequestParams,
    UserInfoResponseDataModel
} from "./types";

import { PortalApi } from "app/api";
import { WebVerb } from "app/helpers/entities";

export const customersEndPoints = PortalApi.injectEndpoints({
    endpoints: builder => ({

        checkCustomer: builder.query<CheckCustomerResponseDto, CheckCustomerRequestParams>({
            query: body => ({
                url: `/customers/check?email=${body.email}`,
                method: WebVerb.POST,
            }),
            providesTags: ["check-customer"]
        }),

        getCustomerInfo: builder.query<UserInfoResponseDataModel, void>({
            query: () => ({
                url: `/customers`,
                method: WebVerb.GET
            }),
            providesTags: ["customer-info"]
        }),

        updateBillingInfo: builder.mutation<UserInfoResponseDataModel, BillingInfoModel>({
            query: body => ({
                url: `/customers/change-billing-address`,
                method: WebVerb.PUT,
                body: body
            }),
            invalidatesTags: ["customer-info"]
        }),

        getSupportedCountries: builder.query<GetSupportCountriesResponseDataModel, void>({
            query: () => ({
                url: `/countries`,
                method: WebVerb.GET
            })
        }),

        setPassword: builder.mutation<void, SetPasswordRequestParams>({
            query: body => ({
                url: "/customers/set-password",
                method: WebVerb.POST,
                body: body
            }),
            invalidatesTags: ["check-customer"]
        }),

        changePassword: builder.mutation<void, ChangePasswordRequestParams>({
            query: body => ({
                url: "/customers/change-password",
                method: WebVerb.POST,
                body: body
            })
        }),

        changeEmail: builder.mutation<void, ChangeEmailRequestParams>({
            query: body => ({
                url: "/customers/change-email",
                method: WebVerb.POST,
                body: body
            })
        }),
    })
})
