import { CommonPaginatedRequestDataModelWithFilter, WebVerb, commonResponseMapFor } from "app/helpers/entities";
import { CreateSubscriptionParams, CreateSubscriptionResonse, ReceiveSubscriptionResponseDataModel, ReceiveSubscriptionResponseDto, ScheduledSubscriptionChangesResponse, UpdateSubscriptionParams, UpdateSubscriptionResponse } from "./types/types";

import { FamilyItemPricesDto } from "../plans/types";
import { PortalApi } from "app/api";

export const subscriptionsEndPoints = PortalApi.injectEndpoints({
    endpoints: builder => ({
        getSubscriptions: builder.query<ReceiveSubscriptionResponseDataModel, CommonPaginatedRequestDataModelWithFilter>({
            query: body => ({
                url: '/subscriptions/query',
                method: WebVerb.POST,
                body: body
            }),
            transformResponse: (response: ReceiveSubscriptionResponseDto) => commonResponseMapFor(response, item => item),
            providesTags: ["subscriptions-list"]
        }),

        getUnpaidInvoices: builder.query<Array<string>, string>({
            query: body => ({
                url: `/subscriptions/${body}/unpaid-invoices`,
                method: WebVerb.GET
            }),
            providesTags: ["unpaid-invoices"]
        }),

        getAttachedItems: builder.mutation<Array<FamilyItemPricesDto>, string>({
            query: planId => ({
                url: `/plans/${planId}/available-or-attached-item-prices`,
                method: WebVerb.GET
            })
        }),

        createSubscription: builder.mutation<CreateSubscriptionResonse, CreateSubscriptionParams>({
            query: body => ({
                url: `/subscriptions`,
                method: WebVerb.POST,
                body: body
            }),
            invalidatesTags: ["subscriptions-list"]
        }),

        completeTrialPeriod: builder.mutation<string, string>({
            query: id => ({
                url: `/subscriptions/${id}/complete-trial-period`,
                method: WebVerb.POST
            }),
            invalidatesTags: ["unpaid-invoices", "subscriptions-list"]
        }),

        cancelSubscription: builder.mutation<void, string>({
            query: id => ({
                url: `subscriptions/${id}/cancel`,
                method: WebVerb.POST
            }),
            invalidatesTags: ["subscriptions-list"]
        }),

        updateSubscription: builder.mutation<UpdateSubscriptionResponse, UpdateSubscriptionParams>({
            query: ({ id, items }) => ({
                url: `subscriptions/${id}`,
                method: WebVerb.PUT,
                body: { items }
            }),
            invalidatesTags: ["subscriptions-list", "invoices-list", "scheduled-changes"]
        }),

        getScheduledChanges: builder.query<ScheduledSubscriptionChangesResponse, string>({
            query: id => ({
                url: `subscriptions/${id}/scheduled-changes`,
                method: WebVerb.GET
            }),
            providesTags: ["scheduled-changes"]
        }),

        cancelScheduledChanges: builder.mutation<void, string>({
            query: id => ({
                url: `subscriptions/${id}/scheduled-changes`,
                method: WebVerb.DELETE
            }),
            invalidatesTags: ["subscriptions-list", "scheduled-changes"]
        }),
    })
})
