import { BottomNavigationAction, Box } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';

import React from 'react';
import { colorToRGBAwithOpacity } from 'app/helpers/functions/colorToRGBAwithOpacity';
import { useAppContext } from 'app/providers/hooks/useAppContext';
import { useProjectInfo } from 'app/views/components/hooks/useProjectInfo';
import useWindowDimension from 'app/views/components/hooks/useWindowDimension';

export default function BottomNavigation() {
    const location = useLocation();
    const { langContext: { getTranslations }, navigation: { menu } } = useAppContext();
    const { isMobileSM } = useWindowDimension();

    const { brandColor } = useProjectInfo();
    const { translate } = getTranslations("navigation");

    const clickHandler = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, link: string) => {
        if (location.pathname === link) event.preventDefault();
    }

    const visibileMenuItems = menu.filter(it => it.isVisibile);
    return <Box sx={{ width: "100%", position: "fixed", bottom: 0, backgroundColor: "#fff", justifyContent: "center" }} data-testid="BottomNavigation" >
        <Box sx={{ backgroundColor: "#fff", display: "flex" }}>
            {
                visibileMenuItems.map(menu => (<NavLink style={{ flex: 1 }} onClick={e => clickHandler(e, menu.link!)} key={menu.link} to={menu.link!}>
                    {
                        ({ isActive }) => <BottomNavigationAction
                            sx={{
                                color: isActive ? brandColor : undefined,
                                backgroundColor: isActive ? colorToRGBAwithOpacity(brandColor) : undefined,
                                padding: "8px",
                                minWidth: "",
                                width: "100%"
                            }}
                            icon={<React.Fragment>
                                <span>
                                    {menu.icon && menu.icon({ style: { color: isActive ? brandColor : "#183b56" } })}
                                </span>
                                <span>{isMobileSM ? undefined : translate(menu.title)}</span>
                            </React.Fragment>}
                        />
                    }
                </NavLink>))
            }
        </Box>
    </Box>
}
