/**
 * Contains Routes of Application
 */
export const AppUriPath = (() => {
    const basePath = "/spa/";
    const widgetsPath = "/widgets";
    return {
        signIn: `${basePath}login`.toLowerCase(),
        signUp: `${basePath}signup`.toLowerCase(),
        subscriptions: `${basePath}subscriptions`.toLowerCase(),
        subscriptionSetup: `${basePath}subscription-setup`.toLowerCase(),
        onboarding: `${basePath}subscription-setup/onboarding`.toLowerCase(),
        invoices: `${basePath}invoices`.toLowerCase(),
        account: `${basePath}account`.toLowerCase(),
        addons: `${basePath}addons`.toLowerCase(),
        plans: `${basePath}plans`.toLowerCase(),
        charges: `${basePath}charges`.toLowerCase(),
        createSinglePayment: `${basePath}single-payment`.toLowerCase(),
        notfound: `${basePath}notfound`.toLowerCase(),
        checkout: `${basePath}checkout`.toLowerCase(),
        translations: `${basePath}lexems`.toLowerCase(),
        session: `${basePath}sessions/authorize`.toLowerCase(),
        widgets: `${widgetsPath}`.toLowerCase(),
        widgets_invoiceCard: `${widgetsPath}/invoice-card`.toLowerCase(),
    };
})();
