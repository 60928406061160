import { ListItem, ListItemButton } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';

import { MenuList } from '../MenuItems/types';
import React from 'react';
import cn from 'classnames';
import { colorToRGBAwithOpacity } from 'app/helpers/functions/colorToRGBAwithOpacity';
import css from '../styles/styles.module.css';
import { useAppContext } from 'app/providers/hooks/useAppContext';
import { useProjectInfo } from 'app/views/components/hooks/useProjectInfo';

export const SimpleMenuItemView: React.FC<MenuList> = (args: MenuList) => {
    const { langContext: { getTranslations } } = useAppContext();

    const location = useLocation();
    const { brandColor } = useProjectInfo();
    const { translate } = getTranslations("navigation");

    const activeStyles: React.CSSProperties = {
        backgroundColor: colorToRGBAwithOpacity(brandColor) ?? "",
    }

    const clickHandler = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (location.pathname === args.link) event.preventDefault();
    }


    return (<ListItem key={Math.random()} className={cn(css['menu-list-item'])}>
        <NavLink
            onClick={event => clickHandler(event)}
            to={args.link!}
            replace
            className={cn(css['menu-list-link'])}
            style={({ isActive }) => isActive ? activeStyles : undefined}
        >
            {({ isActive }) => (
                <ListItemButton
                    sx={{ fontSize: '16px', color: isActive ? brandColor : "#183b56", }}
                >
                    <span className={cn(css['collapse-menu-item-icon'])} style={{ marginRight: '16px' }}>
                        {args.icon && args.icon({ style: { color: isActive ? brandColor : "#183b56" } })}
                    </span>
                    <span>{translate(args.title)}</span>
                </ListItemButton>)}
        </NavLink>
    </ListItem>)
}
