import React, { PropsWithChildren, createContext, useMemo } from "react";

import { DateTimeFormatter } from "app/helpers/utils/DateTimeUtil";
import { useTranslations } from "app/providers/LangProvider/functions/useTranslations";

export interface IDateTimeProvider {
  dateToDayMonthYearHourMinute: (date: Date | string) => string;
  dateToDayMonthYear: (date: Date | string) => string;
  dateToMonthYear: (date: Date | string) => string;
  dateToMonth: (date: Date | string) => string;
  dateToQuarter: (date: Date | string) => string;
  dateToWeek: (date: Date | string) => string;
  dateToDayMonth: (date: Date | string) => string;
  dateToDayMonthYearWeekDay: (date: Date | string) => string;
}

export const DateTimeFormatContext = createContext<IDateTimeProvider>(undefined as any);

export const DateTimeProvider: React.FC<PropsWithChildren> = ({ children }: PropsWithChildren) => {
  const { currentLang } = useTranslations("accountPage");
  const lang = currentLang === "enDefault" ? "en" : currentLang
  const { formatDateTime } = useMemo(() => new DateTimeFormatter(lang), [lang]);

  const getQuarter = (date: Date) => {
    return Math.floor(date.getMonth() / 3 + 1);
  }

  function getWeekNumberInMonth(date: Date): number {
    const clonedDate = new Date(date);
    clonedDate.setDate(1);

    const firstDayOfWeek = clonedDate.getDay();
    const weekNumber = Math.ceil((date.getDate() + firstDayOfWeek) / 7);

    return weekNumber;
  }

  const hasDatevalue = (date: any) => {
    const hasNotValue = date === "" || !date;
    return !hasNotValue;
  }

  const dateToDayMonthYearHourMinute = (date: Date | string) => {
    if (!hasDatevalue(date)) return "-----------";
    return formatDateTime(new Date(date), "DD_MMM_YYYY", "HH_MM")
  }

  const dateToDayMonthYear = (date: Date | string) => {
    if (!hasDatevalue(date)) return "-----------";
    return formatDateTime(new Date(date), "DD_MMM_YYYY")
  }

  const dateToMonthYear = (date: Date | string) => {
    if (!hasDatevalue(date)) return "-----------";
    return formatDateTime(new Date(date), "MMM_YYYY")
  }

  const dateToMonth = (date: Date | string) => {
    if (!hasDatevalue(date)) return "-----------";
    return formatDateTime(new Date(date), "MMM")
  }

  const dateToDayMonth = (date: Date | string) => {
    if (!hasDatevalue(date)) return "-----------";
    return formatDateTime(new Date(date), "DD_MMM")
  }

  const dateToDayMonthYearWeekDay = (date: Date | string) => {
    if (!hasDatevalue(date)) return "-----------";
    return formatDateTime(new Date(date), "DD_MMM_YYYY_WD")
  }

  const dateToQuarter = (date: Date | string) => {
    if (!hasDatevalue(date)) return "-----------";
    const d = new Date(date);
    const quarter = getQuarter(d);
    const year = formatDateTime(new Date(date), "YYYY");
    return `Q${quarter} ${year}`
  }

  const dateToWeek = (date: Date | string) => {
    if (!hasDatevalue(date)) return "-----------";
    const d = new Date(date);
    const week = getWeekNumberInMonth(d);
    const month = formatDateTime(new Date(date), "MMM_YYYY");
    return `W${week} ${month}`
  }

  const providerValue: IDateTimeProvider = {
    dateToDayMonthYearHourMinute,
    dateToDayMonthYear,
    dateToMonthYear,
    dateToMonth,
    dateToQuarter,
    dateToWeek,
    dateToDayMonth,
    dateToDayMonthYearWeekDay
  }

  return (
    <DateTimeFormatContext.Provider value={providerValue}>
      {children}
    </DateTimeFormatContext.Provider>
  );
};

export const useDateTimeFormatter = () =>
  React.useContext(DateTimeFormatContext);