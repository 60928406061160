/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useEffect, useRef } from "react";
import { useActions, useAppState } from "app/store/hooks";

import { ReduxFormFields } from "../slices/redux-form-slice/types";

type ReduxFormHookOptions = {
    reduxFormName: string;
    resetOnUnmount?: boolean;
};

export function useReduxForm<TFormData extends ReduxFormFields>(options: ReduxFormHookOptions) {
    const { reduxFormName, resetOnUnmount = true } = options;
    const formData = useAppState(state => state.reduxForm)[reduxFormName];
    const { initReduxFormReducer, resetReduxFormReducer, updateReduxFormReducer } = useActions();

    const isInitialized = formData?.isInitialized;

    const initReduxForm = (payload: TFormData): void => {
        initReduxFormReducer({
            formName: reduxFormName,
            values: payload,
        });
    };

    const updateReduxForm = (payload: Partial<TFormData>): void => {
        updateReduxFormReducer({
            formName: reduxFormName,
            values: payload,
        });
    };

    const resetReduxForm = () => {
        resetReduxFormReducer({
            formName: reduxFormName,
        });
    };

    const getCurrentReduxFormValues = (): TFormData => {
        const currentValues = formData?.currentValues ?? {};
        const initialValues = formData?.initialValues ?? {};

        const changedFields = Object.keys(currentValues).reduce((diff, key) => {
            if (initialValues[key] === currentValues[key]) return diff;
            return {
                ...diff,
                [key]: currentValues[key]
            };
        }, {});
        return {
            ...initialValues,
            ...changedFields
        } as TFormData;
    };

    const getChangedReduxFormValues = (): Partial<TFormData> => {
        const currentValues = formData?.currentValues ?? {};
        const initialValues = formData?.initialValues ?? {};

        return Object.keys(currentValues).reduce((diff, key) => {
            if (initialValues[key] === currentValues[key]) return diff;
            return {
                ...diff,
                [key]: currentValues[key]
            };
        }, {});
    };

    useEffect(() => {
        return () => {
            if (resetOnUnmount) {
                resetReduxForm();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const reduxForm = {
        initForm: initReduxForm,
        updateForm: updateReduxForm,
        resetForm: resetReduxForm,
        reinitForm: () => {
            if (isInitialized) {
                initReduxForm(formData!.initialValues as TFormData);
            }
        },
        isInitialized: isInitialized,
        hasChanges: Object.keys(getChangedReduxFormValues()).length > 0,
        data: {
            currentValues: getCurrentReduxFormValues(),
            changedValues: getChangedReduxFormValues(),
        },
    };

    return reduxForm;
}
