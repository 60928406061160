import { Translations } from 'app/helpers/utils/i18n/types';
import i18n from 'i18next';
import { useTranslation as useI18nextTranslation } from 'react-i18next';

export type Namespace = keyof Translations;

export type DynamicContent = Record<string, string | JSX.Element | number | boolean | object>;

export const useTranslations = <N extends Namespace>(namespace: N) => {
    const { t } = useI18nextTranslation();
    const currentLang = i18n.language;

    const translate = <K extends keyof Translations[N]>(key: K, dynamicContent?: DynamicContent): string => {
        const translationKey = `${namespace}.${key as string}`;
        return t(translationKey, { ...dynamicContent });
    };

    const changeLanguage = (newLanguage: string) => {
        try {
            i18n.changeLanguage(newLanguage);
        } catch (error) {
            console.error('Language change failed:', error);
        }
    };

    return { translate, changeLanguage, currentLang };
};
