import { Link, ListItem, ListItemButton } from '@mui/material';

import { CustomIcons } from 'app/helpers/icons';
import React from 'react'
import cn from 'classnames';
import css from '../PortalMenu/styles/styles.module.css';
import { useAppContext } from 'app/providers/hooks/useAppContext';
import { useProjectInfo } from 'app/views/components/hooks/useProjectInfo';

export const SignOutLink: React.FC<{ useBrandColor?: boolean }> = ({ useBrandColor }: { useBrandColor?: boolean }) => {

    const { authContext: { signOut }, langContext: { getTranslations } } = useAppContext();
    const { brandColor } = useProjectInfo();
    const { translate } = getTranslations("navigation");

    return (<ListItem key={Math.random()} className={cn(css['menu-list-item'])} style={{ paddingLeft: '0px', }}>
        <Link className={cn(css['menu-list-link'])} style={{ textDecoration: "none" }}>
            <ListItemButton
                style={{ fontSize: '16px' }}
                data-testid="sign-out-button"
                onClick={signOut}>
                <span className={cn(css['collapse-menu-item-icon'])} style={{ marginRight: '16px' }}>{CustomIcons.signoutSvg({ style: { color: useBrandColor ? brandColor : undefined } })}</span>
                <span>{translate("signOut")}</span>
            </ListItemButton>
        </Link>
    </ListItem>)
}
